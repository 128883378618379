import { Controller } from '@hotwired/stimulus';

export default class FormTimesController extends Controller {
  static targets = ['startTime', 'lengthMinutes', 'endTime'];

  declare startTimeTarget: HTMLInputElement;
  declare lengthMinutesTarget: HTMLInputElement;
  declare endTimeTarget: HTMLInputElement;
  declare hasStartTimeTarget: HTMLInputElement;
  declare hasEndTimeTarget: HTMLInputElement;

  // Event handler for updating the break end time based on break length
  updateEndTime() {
    if (this.hasStartTimeTarget && this.hasEndTimeTarget) {
      const breakArrivalTime = this.startTimeTarget?.value;
      const lengthMinutesTarget = parseInt(this.lengthMinutesTarget.value, 10);

      if (breakArrivalTime && !Number.isNaN(lengthMinutesTarget)) {
        const breakArrivalMinutes = this.timeToMinutes(breakArrivalTime);
        const breakEndMinutes = breakArrivalMinutes + lengthMinutesTarget;
        this.endTimeTarget.value = this.minutesToTime(breakEndMinutes);
      }
    }
  }

  // Event handler for updating the break length based on break end time
  updatelengthMinutes() {
    const breakArrivalTime = this.startTimeTarget.value;
    const breakEndTime = this.endTimeTarget.value;

    if (breakArrivalTime && breakEndTime) {
      const breakArrivalMinutes = this.timeToMinutes(breakArrivalTime);
      const breakEndMinutes = this.timeToMinutes(breakEndTime);
      const lengthMinutesTarget = breakEndMinutes - breakArrivalMinutes;
      this.lengthMinutesTarget.value = lengthMinutesTarget.toString();
    }
  }

  // Helper function to convert "HH:MM" format to total minutes
  timeToMinutes(timeStr: string): number {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // Helper function to convert minutes to "HH:MM" format
  minutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60)
      .toString()
      .padStart(2, '0');
    const mins = (minutes % 60).toString().padStart(2, '0');
    return `${hours}:${mins}`;
  }
}
