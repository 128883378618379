import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class InvoicingDashboardController extends Controller {
  declare locationTarget: HTMLSelectElement;

  static targets = ['location'];

  handleChange(event: Event) {
    event.preventDefault();

    const locationValue = this.locationTarget.value;
    Turbo.visit(`${this.data.get('urlValue')}/${locationValue}`);
  }
}
