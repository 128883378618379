import { Controller } from '@hotwired/stimulus';
import Sortable, { type SortableEvent } from 'sortablejs';

export default class DragAndDropController extends Controller {
  static targets: string[] = ['tasksContainer', 'taskOrderBanner', 'resetButton', 'submitButton'];

  declare tasksContainerTargets: HTMLElement[];
  declare taskOrderBannerTarget: HTMLElement;
  declare resetButtonTarget: HTMLButtonElement;
  declare submitButtonTarget: HTMLButtonElement;

  private newOrder: { [key: string]: string[] } = {};
  private originalOrder: { [key: string]: string[] } = {};
  private sortables: { [key: string]: Sortable } = {}; // Store Sortable instances
  private selectedElement: { task: HTMLElement | undefined; container: HTMLElement | undefined } = {
    task: undefined,
    container: undefined,
  };

  connect() {
    this.setupSortable();
    this.taskOrderBannerTarget.style.display = 'none';
  }
  setupSortable() {
    this.tasksContainerTargets.forEach((container) => {
      const routeId = container.dataset.routeId as string;
      const vehicleId = container.dataset.vehicleId as string;

      const sortable = Sortable.create(container, {
        group: `tasks-${routeId}`, // Use a unique group identifier for each route
        animation: 150,
        handle: '.sortable-handle',
        filter: '.start-task, .end-task',
        scroll: true,
        swapThreshold: 0.7,
        scrollSensitivity: 15, // px, how near the mouse must be to an edge to start scrolling.
        scrollSpeed: 80, // px, speed of the scrolling
        bubbleScroll: true, // apply autoscroll to all parent elements, allowing for easier movement
        onStart: (event: SortableEvent) => this.onDragStart(event),
        onEnd: (event: SortableEvent) => this.onDragEnd(event, container, vehicleId),
      });

      if (routeId) {
        this.originalOrder[routeId] = sortable.toArray();
        this.sortables[routeId] = sortable;
      }
    });
  }

  private onDragStart(event: SortableEvent) {
    this.taskOrderBannerTarget.style.display = 'none';
    event.item.classList.add('chosen');
  }

  private onDragEnd(event: SortableEvent, container: HTMLElement, vehicleId: string) {
    const taskElements = container.querySelectorAll('.task-card');
    const newOrder = [...taskElements].map((task) => task.dataset.id as string);

    this.setNewOrder(newOrder, vehicleId);

    event.item.classList.remove('chosen');
  }

  setNewOrder(newOrder: string[], vehicleId: string) {
    // Find the hidden fields for this specific route
    const hiddenOrderField = document.querySelector<HTMLInputElement>(`#new_order_input_${vehicleId}`);

    if (hiddenOrderField) {
      hiddenOrderField.value = JSON.stringify(newOrder);
    }

    this.taskOrderBannerTarget.style.display = 'flex';
  }

  resetOrder() {
    this.tasksContainerTargets.forEach((container) => {
      const routeId = container.dataset.routeId as string;
      if (routeId) {
        const originalTaskOrder = this.originalOrder[routeId];
        const sortable = this.sortables[routeId];

        sortable.sort(originalTaskOrder);
      }
    });

    // Hide the submit and reset buttons and deselect all checkboxes
    this.taskOrderBannerTarget.style.display = 'none';
    this.deSelectAll();
  }

  selectTask(event: Event) {
    // Hide Task remover banner
    const banner = document.querySelector('#task-banner') as HTMLElement;
    banner.style.display = 'none';

    // Allow user to select only one checkbox at a time
    const target = event.target as HTMLInputElement;

    if (target?.checked) {
      this.deSelectAll();

      target.checked = true;

      this.tasksContainerTargets.forEach((container) => {
        const tasks = container.querySelectorAll('.timeline-task-container');
        const selectedTask = [...tasks].find((task) => task.querySelector('.task-checkbox:checked') !== null);
        if (selectedTask) {
          this.selectedElement.task = selectedTask;
          this.selectedElement.container = container;
        }
      });
    } else {
      this.selectedElement.task = undefined;
      this.selectedElement.container = undefined;
    }
  }

  deSelectAll() {
    const checkBoxes = document.querySelectorAll('.task-checkbox');
    checkBoxes.forEach((checkbox) => {
      const input = checkbox as HTMLInputElement;
      input.checked = false;
    });
  }

  sortSelectedTask(event: KeyboardEvent) {
    const { task, container } = this.selectedElement;

    if (task && container) {
      const vehicleId = container.dataset.vehicleId as string;

      this.handleKeyDown(event, task, container, vehicleId);
    }
  }

  handleKeyDown(event: KeyboardEvent, task: HTMLElement, container: HTMLElement, vehicleId: string) {
    const taskId = task.dataset.id;

    if (['ArrowLeft', 'ArrowRight'].includes(event.key) === false) {
      return;
    }
    if (taskId === undefined) {
      return;
    }

    const routeId = container.dataset.routeId as string;
    const sortable = this.sortables[routeId];
    const order = sortable.toArray();
    const index = order.indexOf(taskId);
    const target = event.target as HTMLInputElement;

    order.splice(index, 1);

    let newIndex = index;

    if (event.key === 'ArrowRight') {
      newIndex = index >= order.length - 1 ? index : index + 1;
    } else if (event.key === 'ArrowLeft') {
      newIndex = index <= 1 ? index : index - 1;
    }

    event.preventDefault(); // prevent page scrolling
    order.splice(newIndex, 0, taskId);
    sortable.sort(order, true);

    order[0] = 'start_';
    order[order.length - 1] = 'end_';
    const newOrder = order;
    this.setNewOrder(newOrder, vehicleId);

    target.focus(); // keep the focus so keydown event can be fired continuously
  }
}
