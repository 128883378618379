/* eslint-disable no-undef */
import '@hotwired/stimulus';
import '@hotwired/turbo-rails';
import Rails from '@rails/ujs';
import 'chartkick/chart.js';
import mapboxgl from 'mapbox-gl';
import '../javascript/controllers/application'; // needed for stimulus js to work in rails

if (document.querySelectorAll('[data-disable-rujs="true"]').length === 0) {
  // For pages that use Rails UJS - Must not be used on TurboStream pages.
  Rails.start();
}

function drawMap(routes, assistants, unassigned, location_lng, location_lat) {
  mapboxgl.accessToken =
    'pk.eyJ1IjoiZWR3aW50b3NoaSIsImEiOiJja3M2b3BrNDYwZTU0MnBzMHV3eW51ajM1In0.uEEKUNmmC61CxsfJXgZWYQ';

  const preferredStyle = localStorage.getItem('mapStyle') || 'mapbox://styles/mapbox/streets-v12';
  let isDarkMode = preferredStyle === 'mapbox://styles/mapbox/dark-v11';

  const map = new mapboxgl.Map({
    container: 'map',
    style: preferredStyle,
    center: [parseFloat(location_lng), parseFloat(location_lat)],
    zoom: 11,
    attributionControl: false,
  });

  const toggleButton = document.getElementById('toggle-style');

  // define the map globally so that we can live update
  window.toshiPlanMap = map;

  const allSteps = [];

  const loadMapData = () => {
    routes.forEach((route) => {
      let coordinates = route.coordinates;
      let routeName = `route-${Date.now()}-${routes.indexOf(route)}`;
      map.addSource(routeName, {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        },
      });

      map.addLayer({
        id: routeName,
        type: 'line',
        source: routeName,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': route.colour,
          'line-width': 4,
          'line-opacity': 0.5,
        },
      });
    });
  };

  map.on('load', loadMapData);

  // Event listener to toggle the map style
  toggleButton.addEventListener('click', () => {
    const newStyle = isDarkMode ? 'mapbox://styles/mapbox/streets-v12' : 'mapbox://styles/mapbox/dark-v11';
    localStorage.setItem('mapStyle', newStyle);
    isDarkMode = !isDarkMode;

    map.setStyle(newStyle);
    map.on('style.load', loadMapData);
  });
  // Create a dictionary to store steps by coordinates
  const stepsByCoordinates = {};
  const baseUri = window.location.host;

  routes.forEach((route) => {
    route.steps.forEach((step, index) => {
      const key = `${step.longitude},${step.latitude}`;
      if (!stepsByCoordinates[key]) {
        stepsByCoordinates[key] = [];
      }
      stepsByCoordinates[key].push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [step.longitude, step.latitude],
        },
        properties: {
          title: `${step.type}: ${step.journey_id}`,
          number: index + 1, // Step order index
          colour: step.colour,
          url: `https://${baseUri}/ops/journeys/${step.journey_id}`,
        },
      });
    });
  });

  unassigned.forEach((step) => {
    const key = `${step.longitude},${step.latitude}`;
    if (!stepsByCoordinates[key]) {
      stepsByCoordinates[key] = [];
    }
    stepsByCoordinates[key].push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [step.longitude, step.latitude],
      },
      properties: {
        title: `${step.type}: ${step.id}`,
        number: 0,
        colour: step.colour,
        url: `https://${baseUri}/ops/journeys/${step.id}`, // Add URL property
      },
    });
  });

  // Flatten stepsByCoordinates into allSteps array
  for (const key in stepsByCoordinates) {
    allSteps.push(...stepsByCoordinates[key]);
  }

  map.on('load', () => {
    map.addSource('steps', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: allSteps,
      },
    });
  });

  // Add markers for each step
  for (const key in stepsByCoordinates) {
    const steps = stepsByCoordinates[key];
    const baseCoordinates = steps[0].geometry.coordinates;
    const baseLng = parseFloat(baseCoordinates[0]); // Parse longitude as float
    const baseLat = parseFloat(baseCoordinates[1]); // Parse latitude as float
    const offsetStep = 0.00005; // Adjust this value to spread the points further apart

    steps.forEach((step, index) => {
      const offsetCoordinates = [baseLng + offsetStep * index, baseLat];

      // Create an anchor element
      const anchor = document.createElement('a');
      anchor.href = step.properties.url; // Set the URL
      anchor.style.textDecoration = 'none'; // Remove underline from the link
      anchor.style.color = 'inherit'; // Inherit text color
      anchor.style.display = 'flex';
      anchor.style.alignItems = 'center';
      anchor.style.justifyContent = 'center';

      const el = document.createElement('div');
      el.className = steps.length > 1 ? 'maps-marker-step' : 'maps-marker-step-square'; // Conditional class name
      el.style.backgroundColor = step.properties.colour;
      el.textContent = step.properties.number; // Display index value on marker
      el.style.color = 'white';
      el.style.fontSize = '12px';
      el.style.width = '20px';
      el.style.height = '20px';
      el.style.display = 'flex';
      el.style.alignItems = 'center';
      el.style.justifyContent = 'center';

      anchor.appendChild(el); // Append the marker element to the anchor

      if (steps.length > 1) {
        el.style.borderRadius = '50%'; // Circular for multiple points
      }

      const popupContent =
        steps.length === 1
          ? `<a href="${step.properties.url}" target="_blank" style="text-decoration: none; color: inherit;">${step.properties.title}</a>`
          : steps
              .map(
                (s, index) =>
                  `${s.properties.number}. <a href="${s.properties.url}" target="_blank" style="text-decoration: none; color: inherit;">${s.properties.title}</a>`,
              )
              .join('<br>');

      const marker = new mapboxgl.Marker(el)
        .setLngLat(offsetCoordinates)
        .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
        .addTo(map);
    });
  }

  const assistantsGeojson = {
    type: 'FeatureCollection',
    features: assistants.map((assistant) => mapAssistantToMapBoxSchema(assistant)),
  };

  for (const feature of assistantsGeojson.features) {
    addAssistantToMap(feature);
  }
}

function removeAssistantFromMap(key) {
  window.currentAssistantsOnMap[key].remove();
  delete window.currentAssistantsOnMap[key];
}

function addAssistantToMap(feature) {
  const map = window.toshiPlanMap;
  const el = document.createElement('div');
  el.className = 'maps-marker van';
  el.style.backgroundColor = feature.properties.colour;
  el.textContent = feature.properties.initials;

  // make a marker for each feature and add to the map
  new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates).addTo(map);
  new mapboxgl.Marker(el)
    .setLngLat(feature.geometry.coordinates)
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(feature.properties.title),
    )
    .addTo(map);

  window.currentAssistantsOnMap[feature.properties.id] = el;
}

function mapAssistantToMapBoxSchema(assistant) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [assistant.longitude, assistant.latitude],
    },
    properties: {
      id: assistant.id,
      initials: assistant.initials,
      title: assistant.name,
      colour: assistant.colour,
    },
  };
}

function toggleMap() {
  document.querySelector('#map')?.toggleAttribute('hidden');
  window.toshiPlanMap?.resize();
}

// make these functions global since they're called by name in erb
window.toggleMap = toggleMap;
window.mapAssistantToMapBoxSchema = mapAssistantToMapBoxSchema;
window.removeAssistantFromMap = removeAssistantFromMap;
window.currentAssistantsOnMap = {};
window.addAssistantToMap = addAssistantToMap;
window.drawMap = drawMap;
