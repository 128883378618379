import { Controller } from '@hotwired/stimulus';

export default class LabelPrinterName extends Controller {
  static targets = ['input', 'display'];

  connect() {
    this.updateDisplay();
    this.inputTarget.addEventListener('input', this.updateDisplay.bind(this));
  }

  updateDisplay() {
    this.displayTarget.textContent = this.inputTarget.value;
  }
}
