import { Controller } from '@hotwired/stimulus';

export default class ReassignController extends Controller {
  static targets = ['hiddenField', 'form'];

  submit() {
    this.updateSelectedTasks();
  }

  updateSelectedTasks() {
    const selectedCheckboxes = document.querySelectorAll('.task-checkbox:checked');
    const taskIds = [...selectedCheckboxes].map((cb) => cb.value);

    // Clear existing values
    this.hiddenFieldTarget.innerHTML = '';
    // Set each selected ID as its own hidden field so we can pass it as an array to Rails controller
    taskIds.forEach((id) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'journey_hashed_ids[]';
      input.value = id;
      this.hiddenFieldTarget.append(input);
    });
  }
}
