import { Controller } from '@hotwired/stimulus';

export default class ToggleEnabledController extends Controller {
  static targets = ['checkbox', 'toggleable'];

  declare readonly checkboxTarget?: HTMLElement;
  declare toggleableTarget?: HTMLElement;

  connect() {
    this.toggleElement(); // Ensure correct state on initial load
  }

  toggleElement() {
    const isChecked = (this.checkboxTarget as HTMLInputElement).checked;
    (this.toggleableTarget as HTMLButtonElement).disabled = !isChecked;
  }

  // Triggered whenever the checkbox state changes
  toggle() {
    this.toggleElement();
  }
}
