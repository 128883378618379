import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

export default class MultiSelectController extends Controller {
  static targets = ['select'];
  declare selects: TomSelect[];

  connect() {
    this.selects = this.selectTargets.map((selectElement) => this.initializeTomSelect(selectElement));
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect(element) {
    return new TomSelect(element, {
      plugins: {
        checkbox_options: {
          checkedClassNames: ['ts-checked'],
          uncheckedClassNames: ['ts-unchecked'],
        },
        remove_button: {
          title: 'Remove this item',
        },
      },
    });
  }

  destroyTomSelect() {
    if (this.selects) {
      this.selects.forEach((select) => select.destroy());
    }
  }
}
