import { Controller } from '@hotwired/stimulus';

export default class AssistantModalController extends Controller {
  static targets = ['modal'];

  declare modalTarget: HTMLElement;

  open(event) {
    event.preventDefault();
    this.modalTarget.classList.remove('hidden');
    this.modalTarget.classList.add('flex');
  }

  close(event) {
    event.preventDefault();
    this.modalTarget.classList.add('hidden');
    this.modalTarget.classList.remove('flex');
  }
}
