import { Controller } from '@hotwired/stimulus';

export default class TaskBannerController extends Controller {
  static targets = ['checkbox', 'banner', 'unassignButton', 'deselectButton'];

  connect() {
    this.updateBannerVisibility();
    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', this.updateBannerVisibility.bind(this));
    });
    this.deselectButtonTarget.addEventListener('click', this.deselectTasks.bind(this));
  }

  updateBannerVisibility() {
    const selectedCheckboxes = this.checkboxes.filter((checkbox) => checkbox.checked);
    if (selectedCheckboxes.length > 0) {
      this.bannerTarget.style.display = 'flex';
      this.updateUnassignLink(selectedCheckboxes);
    } else {
      this.bannerTarget.style.display = 'none';
      this.clearUnassignLink();
    }
  }

  updateUnassignLink(selectedCheckboxes) {
    const taskIds = selectedCheckboxes.map((checkbox) => checkbox.value);
    const link = this.unassignButtonTarget.querySelector('a');
    if (link) {
      const currentUrl = new URL(link.href, window.location.origin);
      currentUrl.searchParams.set('task_ids', taskIds.join(','));
      link.href = currentUrl.toString();
    }
  }

  clearUnassignLink() {
    const link = this.unassignButtonTarget.querySelector('a');
    if (link) {
      const currentUrl = new URL(link.href, window.location.origin);
      currentUrl.searchParams.delete('task_ids');
      link.href = currentUrl.toString();
    }
  }

  deselectTasks(event) {
    event.preventDefault();
    // eslint-disable-next-line no-return-assign,no-param-reassign
    this.checkboxes.forEach((checkbox) => (checkbox.checked = false));
    this.bannerTarget.style.display = 'none';
    this.clearUnassignLink();
  }

  get checkboxes() {
    return [...this.checkboxTargets];
  }
}
